<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/room" class="main_left" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
  <vxe-modal ref="xModal" :title="`生成费用(${currNode.name})`" width="600" show-zoom resize>
    <template #default>
      <vxe-form ref="xForm" v-bind="costOptions" @submit="submitEvent" @reset="closeModal"></vxe-form>
    </template>
  </vxe-modal>
  <vxe-modal ref="xModalDetails" title="详情" width="1000" height="600">
    <template v-slot>
      <Details :selectRow="selectRow"></Details>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import Details from "./Details.vue";
import { mapActions } from 'vuex';
export default {
  components: {
    Details
  },
  data() {
    return {
      selectRow: {},
      mapKeys: {
        '1001': 'communityId',
        '1002': 'floorId',
        '1003': 'unitId',
        '1004': 'objId',
      },
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
            code: "generated",
            name: "生成费用",
            status: "primary",
            events: {
              click: () => {
                let typeList = ['1001', '1002', '1003', '1004', '1005']
                if (typeList.indexOf(this.currNode.type) > -1) {
                  this.getSysDictEvent();
                  this.getFeeConfigLists()
                } else {
                  this.$message.error('请选择小区、楼栋、单元或房间')
                }
              }
            }
          }, ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "objName",
            title: "房间编号",
            minWidth: 160,
          },
          {
            field: "equName",
            title: "设备名称",
            minWidth: 160,
          },
          {
            field: "preDegrees",
            title: "上期度数",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,

                maxlength: 24,
                placeholder: "请输入上期度数"
              },
              events: {
                change: ({ row }) => {
                  row["status"] = '1'
                }
              }
            }
          },
          {
            field: "curDegrees",
            title: "本期度数",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入本期度数"
              },
              events: {
                change: ({ row }) => {
                  row["status"] = '1'
                  if (row.curDegrees < row.preDegrees) {
                    row.curDegrees = row.preDegrees
                    this.$message.error('本期度数不能低于上期度数')
                  }
                }
              }
            }
          },
          {
            field: "preReadingTime",
            title: "上期读表时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择上期读表时间"
              },
              events: {
                change: ({ row }) => {
                  row["status"] = '1'
                }
              }
            }
          },
          {
            field: "curReadingTime",
            title: "本期读表时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择本期读表时间"
              },
              events: {
                change: ({ row }) => {
                  row["status"] = '1'
                  if (new Date(row.curReadingTime) < new Date(row.preReadingTime)) {
                    row.curReadingTime = row.preReadingTime
                    this.$message.error('本期读表时间不能在上期时间之前')
                  }
                }
              }
            }
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                status: "primary",
                label: "详情",
                name: 'details',
                events: {
                  click: (row) => {
                    this.selectRow = row
                    this.$refs.xModalDetails.open()
                  }
                }
              }, ]
            }
          }
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [],
        //表单校验规则
        rules: {
          preDegrees: [{ required: true, message: "请输入上期度数" }],
          curDegrees: [{ required: true, message: "请输入本期度数" }],
          preReadingTime: [{ required: true, message: "请选择上期读表时间" }],
          curReadingTime: [{ required: true, message: "请选本期读表时间" }],
        },
        // 新增功能字段初始化
        data: { configId: '' }
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "equName",
            title: "设备名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输设备名称",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {

        },
      },
      costOptions: {
        titleWidth: 100,
        items: [{
            field: "configId",
            title: "收费项",
            span: 24,
            itemRender: {
              name: "$select",
              props: {
                placeholder: "请选择收费项",
              },
              events: {
                click: (params, node) => {
                  let { data, property } = params;
                  data[property] = node.id;
                  data["chargeType"] = node.chargeType;
                  data["squarePrice"] = node.squarePrice;
                  data["fixedPrice"] = node.fixedPrice;
                }
              }
            },
          },
          {
            field: "equpClass",
            title: "设备类别",
            span: 24,
            itemRender: {
              name: "$select",
              optionTypeCode: "facilitiesType",
              props: {
                placeholder: "请选择设备类别",
              },
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        rules: {
          configId: [{ required: true, message: "请选择收费项" }],
          equpClass: [{ required: true, message: "请选设备类型" }],
        },
        data: {
          configId: "",
          equpClass: "",
        },
      }
    };
  },
  methods: {
    ...mapActions(['getSysDictService', 'findByEventService', 'submitEventService']),
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.costOptions.data['communityId'] = node.communityId;
      this.searchOptions.data[this.mapKeys[node.type]] = node.id;
      this.searchOptions.data['type'] = node.type;
      this.$refs.xGrid.getLists();
    },

    // 获取数据字典
    getSysDictEvent() {
      this.getSysDictService({
          params: ["facilitiesType"]
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.costOptions.items = [...this.$Tools.buildDataByDicts(this.costOptions.items, data, { value: "code", label: "name" })]
          }
        });
    },

    // 获取费用项
    getFeeConfigLists() {
      this.findByEventService({
          service: "/fee/fPayFeeConfig",
          params: {
            parentId: this.currNode.communityId,
            feeFlag: '2'
          }
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.costOptions.items = [...this.$Tools.buildDataToGrid(this.costOptions.items, data, "configId", { value: "id", label: "feeName" })];
            this.$refs.xModal.open();
          }
        });
    },

    // 生成费用
    submitEvent({ data }) {
      this.$refs["xForm"].validate((valid) => {
        if (!valid) {
          Object.keys(this.costOptions.data).forEach(key => {
            delete this.searchOptions.data[key]
          })
          this.costOptions.data[this.mapKeys[this.currNode.type]] = this.currNode.id;
          this.costOptions.data['type'] = this.currNode.type;
          this.costOptions.data['configId'] = data.configId;
          this.submitEventService({
            service: "/fee/generateFee/generateReadingFee",
            params: data
          }).then(({ code, message }) => {
            if (code == 200) {
              this.$message.success(`【${this.currNode.name}】费用生成成功`)
              this.$refs.xModal.close();
            } else {
              this.$message.error(`【${this.currNode.name}】${message}`)
            }
          })
        }
      });
    },

    // 关闭模态对话框
    closeModal() {
      if (this.$refs.xModal) {
        this.$refs.xModal.close();
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
