<template>
<div class="main">
  <VxeBasicTable ref="xGrid" service="/fee/fMeterWater" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
  </VxeBasicTable>
</div>
</template>

<script>
export default {
  props: {
    selectRow: {
      type: Object
    },
  },
  data() {
    return {
      configList: [],
      service: "/sys/sysOrganization/treeQueryAuthCommon/room",
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "equName",
            title: "设备名称",
            minWidth: 160,

          },
          {
            field: "preDegrees",
            title: "上期度数",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,

                maxlength: 24,
                placeholder: "请输入上期度数"
              },
              events: {
                change: ({ row }) => {
                  row["status"] = '1'
                }
              }

            }
          },
          {
            field: "curDegrees",
            title: "本期度数",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,

                maxlength: 24,
                placeholder: "请输入本期度数"
              },
              events: {
                change: ({ row }) => {
                  row["status"] = '1'
                  if (row.curDegrees < row.preDegrees) {
                    row.curDegrees = row.preDegrees
                    this.$message.error('本期度数不能低于上期度数')
                  }
                }
              }
            }
          },
          {
            field: "preReadingTime",
            title: "上期读表时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择上期读表时间"
              },
              events: {
                change: ({ row }) => {
                  row["status"] = '1'
                }
              }
            }
          },
          {
            field: "curReadingTime",
            title: "本期读表时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择本期读表时间"
              },
              events: {
                change: ({ row }) => {
                  row["status"] = '1'
                  if (new Date(row.curReadingTime) < new Date(row.preReadingTime)) {
                    row.curReadingTime = row.preReadingTime
                    this.$message.error('本期读表时间不能在上期时间之前')
                  }
                }
              }
            }
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [],
        //表单校验规则
        rules: {

        },
        // 新增功能字段初始化
        data: {}
      },

      // 查询功能字段配置
      searchOptions: {
        items: [],
        data: {
          equId: this.selectRow.equId,
          objId: this.selectRow.objId,
          status: 2
        },
      },

    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
